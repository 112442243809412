// On the grants filter, there are subfields of 'region' and 'grant amount' which need to be conditionally
// revealed depending on whether the user selects 'UK' or 'India' from the countries field

$(document).ready(function() {
  var countrySelect = $("dialog select[name='countries']");
  if (countrySelect.length) {
    var optionSelected = $("option:selected", countrySelect);
    showHideFilterSubFields(optionSelected[0].text);
  }
});

$("dialog select[name='countries']").on("change", function(e) {
  var optionSelected = $("option:selected", this);
  showHideFilterSubFields(optionSelected[0].text);
});

function showHideFilterSubFields(text) {
  if(text == "India") {
    $("#fieldsetRegionDisabled").addClass("hidden");
    $("#fieldsetRegionIndia").removeClass("hidden");
    $("#fieldsetRegionUk").addClass("hidden");
    $("#fieldsetRegionUk select").val($("#fieldsetRegionUk select option:first").val());

    $("#fieldsetGrantAmountDisabled").addClass("hidden");
    $("#fieldsetGrantAmountInr").removeClass("hidden");
    $("#fieldsetGrantAmountGbp").addClass("hidden");
    $("#fieldsetGrantAmountGbp select").val($("#fieldsetGrantAmountGbp select option:first").val());
  } else if (text == "UK") {
    $("#fieldsetRegionDisabled").addClass("hidden");
    $("#fieldsetRegionUk").removeClass("hidden");
    $("#fieldsetRegionIndia").addClass("hidden");
    $("#fieldsetRegionIndia select").val($("#fieldsetRegionIndia select option:first").val());

    $("#fieldsetGrantAmountDisabled").addClass("hidden");
    $("#fieldsetGrantAmountGbp").removeClass("hidden");
    $("#fieldsetGrantAmountInr").addClass("hidden");
    $("#fieldsetGrantAmountInr select").val($("#fieldsetGrantAmountInr select option:first").val());
  } else {
    $("#fieldsetRegionDisabled").removeClass("hidden");
    $("#fieldsetRegionIndia").addClass("hidden");
    $("#fieldsetRegionUk").addClass("hidden");
    $("#fieldsetRegionIndia select").val($("#fieldsetRegionIndia select option:first").val());
    $("#fieldsetRegionUk select").val($("#fieldsetRegionUk select option:first").val());

    $("#fieldsetGrantAmountDisabled").removeClass("hidden");
    $("#fieldsetGrantAmountInr").addClass("hidden");
    $("#fieldsetGrantAmountGbp").addClass("hidden");
    $("#fieldsetGrantAmountInr select").val($("#fieldsetGrantAmountInr select option:first").val());
    $("#fieldsetGrantAmountGbp select").val($("#fieldsetGrantAmountGbp select option:first").val());
  }
}
