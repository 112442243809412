$("select[data-scroll-to-next-field-on-change]").on("change", function(e) {
  const $this = $(this);
  const parentFuiRow = $this.parents(".fui-row");
  const nextFuiRow = parentFuiRow.next(".fui-row:not(.fui-row-empty)");
  if (nextFuiRow.length) {
    $("html, body").animate({
      scrollTop: nextFuiRow.offset().top
    }, 0);
  }
});
