const dialog = document.querySelector("dialog");
const showButton = document.querySelector("dialog + button");
const closeButton = document.querySelector("#closeButton");
// const confirmButton = document.querySelector("#confirmButton");

if(showButton) {
  showButton.addEventListener("click", () => {
    dialog.showModal();
  });
}

if(closeButton) {
  closeButton.addEventListener("click", () => {
    dialog.close();
  });
}

// confirmButton.addEventListener("click", (event) => {
//   event.preventDefault();
//   dialog.close();
// });

// showButton.click();
